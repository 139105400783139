import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

import { buttonSubfields } from '@common/Button/Button.BuilderObj';
import { CustomBuilderComponents } from '@store/enums';

const CampaignVimeoModal = dynamic(
  async () => await import('./CampaignVimeoModal')
);

Builder.registerComponent(CampaignVimeoModal, {
  name: CustomBuilderComponents.CAMPAIGN_VIMEO_MODAL,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/dock-left.svg',
  inputs: [
    {
      name: 'textBody',
      friendlyName: 'Overlay text body',
      type: 'customRichTextPistachios',
      required: true,
    },
    {
      name: 'overlayImage',
      type: 'object',
      subFields: [
        {
          name: 'src',
          friendlyName: 'Image source',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
          required: true,
        },
        {
          name: 'altText',
          friendlyName: 'Image description text',
          type: 'string',
        },
      ],
    },
    {
      name: 'backgroundVideoId',
      type: 'string',
    },
    {
      name: 'linkButton',
      friendlyName: 'CTA button',
      type: 'object',
      required: true,
      ...buttonSubfields,
    },
    {
      name: 'modalButtonText',
      type: 'string',
    },
    {
      name: 'videos',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'videoId',
          type: 'string',
          required: true,
        },
      ],
    },
  ],
});
