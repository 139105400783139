import { useEffect } from 'react';
import NextScript from 'next/script';
import { useRouter } from 'next/router';
import config from '@helpers/config';
import { pushToDataLayer } from '@helpers/googleTagManager/googleTagManager';
import { DataLayerEventEnums } from '@helpers/googleTagManager/googleTagManager.types';

export default function GoogleTagManager() {
  const { asPath, locale, defaultLocale } = useRouter();

  const localeString = defaultLocale === locale ? '' : `/${locale}`;

  // Fire pageview on load
  const webUrl = `${config.webUrl.replace(/\/+$/, '')}`;
  useEffect(() => {
    pushToDataLayer(DataLayerEventEnums.PAGEVIEW, {
      url: `${webUrl}${localeString}${asPath}`,
    });
  }, [asPath, localeString, webUrl]);

  return (
    <NextScript
      strategy="lazyOnload" //  Load during idle time
      id="emeawp-gtm"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${config.GtmId}');
          `,
      }}
    />
  );
}
