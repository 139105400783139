import React, { useEffect } from 'react';
import BuilderImage from '@common/BuilderImage/BuilderImage';

import styles from './NutritionLabel.module.scss';

interface NutritionLabelProps {
  label: string;
  isOpen: boolean;
  onClose: () => void;
  dataTestid?: string;
}

function NutritionLabel(props: NutritionLabelProps) {
  useEffect(() => {
    if (props.isOpen) {
      document.body.focus();
    }
  }, [props.isOpen]);
  return (
    <div className={styles.container} data-testid={props.dataTestid}>
      <button
        className={styles.closeModal}
        aria-label="Close Modal"
        onClick={props.onClose}
        onTouchEnd={props.onClose}
      >
        <span id={styles.close}>
          <span />
        </span>
      </button>
      <div className={styles.wrapper}>
        <BuilderImage
          imageSrc={props.label}
          mobileWidth={'200px'}
          tabletWidth={'300px'}
          alt={'Product Nutrition Facts'}
        />
      </div>
    </div>
  );
}

export default NutritionLabel;
