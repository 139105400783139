import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { DefaultSeo, NextSeo } from 'next-seo';
import NextNProgress from 'nextjs-progressbar';
import { appWithTranslation } from 'next-i18next';
import config from '@helpers/config';
import { builder } from '@builder.io/react/lite';
import '../styles/styles.scss';
import { OneTrust } from '@wonderful/wwc/dist/src/modules/OneTrust/OneTrust';
// import components for builder
import '../components/builder';
import { getDefaultLayout } from '@static/Layout/Layout';
import GoogleTagManager from '@modules/GoogleTagManager';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
};

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!, false);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  // Component.getLayout with TS example in 404.tsx file.
  const getLayout = Component.getLayout || getDefaultLayout;
  const { ...restPageProps } = pageProps;
  const progressBarProps = {
    showSpinner: false,
    trickle: false,
    speed: 300,
  };

  // SEO
  const builderSeoTitle =
    pageProps.content?.data?.title || pageProps.content?.name || '';
  const seoTitle =
    builderSeoTitle !== ''
      ? `${builderSeoTitle} | EMEA Pistachios`
      : 'EMEA Pistachios';
  const seoDescription = pageProps.content?.data?.description;
  const seoUrl = `${config.webUrl.replace(/\/+$/, '')}${
    pageProps.content?.data?.url
      ? pageProps.content?.data?.url
      : (router.defaultLocale === router.locale ? '' : `/${router.locale}`) +
        router.pathname
  }`;

  // monotype - record page views
  useEffect(() => {
    const recordMonotypePageViews = async () => {
      try {
        fetch(
          'https://cdn.fonts.net/t/1.css?apiType=css&projectid=b230b330-23cc-11ed-aaf7-06b70d83f6b0'
        );
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    // fires on load
    recordMonotypePageViews();
  }, [router.asPath]);

  return (
    <>
      <Head>
        {config.oneTrustId && <OneTrust scriptID={config.oneTrustId} />}
        <title>{seoTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        ></link>
        <link rel="manifest" href="/site.webmanifest"></link>
        <meta property="og:locale" content={router.locale} />
        <meta property="og:type" content="Website" />
        <meta property="og:site_name" content="EMEA Pistachios" />
      </Head>

      <DefaultSeo
        openGraph={{
          title: seoTitle,
          type: 'website',
          locale: router.locale,
          url: seoUrl,
          description: seoDescription,
          site_name: 'EMEA Pistachios',
        }}
        twitter={{
          handle: '@wonderfulnuts', // creator is called handle here...
          site: '@wonderfulnuts',
          cardType: 'summary_large_image',
        }}
      />

      <GoogleTagManager />

      <NextSeo
        noindex={config.env !== 'production'}
        nofollow={config.env !== 'production'}
      />

      <NextNProgress color="#94cc00" height={5} options={progressBarProps} />
      {getLayout(<Component {...restPageProps} />)}
    </>
  );
}

export default appWithTranslation(MyApp);
