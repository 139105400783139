import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';
import { DesignType } from './TextBox.types';
import { CustomBuilderComponents } from '@store/enums';

const TextBox = dynamic(async () => await import('./TextBox'));

const designs = [...Object.values(DesignType)];

Builder.registerComponent(TextBox, {
  name: CustomBuilderComponents.TEXT_BOX,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
  inputs: [
    {
      name: 'design',
      type: 'string',
      defaultValue: designs[0],
      enum: designs,
    },
    {
      name: 'body',
      friendlyName: 'Text body',
      type: 'customRichTextPistachios',
    },
  ],
});
