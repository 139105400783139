import { useState } from 'react';
import { FilterProps } from './Filter.types';
import Section from 'components/design/Section/Section';
import styles from './Filter.module.scss';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import { useTranslation } from 'next-i18next';

export default function Filter(props: FilterProps) {
  const {
    tags,
    currentFilter,
    componentId,
    ariaLabel,
    setActiveFilter,
    classes,
    hasAll = true,
    ...rest
  } = props;
  const { t } = useTranslation('common');

  // EMEAWP-309 - Adding hasAll in the cases where we don't want filter to include "All" option
  if (hasAll) {
    // Check to see if all is already provided as content for filter tags. If not, adds it.
    if (
      !tags?.some(tag => tag?.toLowerCase() === `${t('all')}`.toLowerCase())
    ) {
      tags?.unshift(`${t('all')}`.toLowerCase());
    }
  }

  const [isInViewport, setIsInViewport] = useState(false);

  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  return (
    <Section
      className={clsx(
        styles.filterSection,
        classes,
        isInViewport ? styles.inViewport : ''
      )}
      onEnterViewport={onEnterViewport}
      {...rest}
    >
      <div
        className={styles.filterButtons}
        role="radiogroup"
        aria-label={ariaLabel}
        aria-controls={componentId}
      >
        {tags?.map((filterTag, index) => {
          const tag = filterTag.toLowerCase();
          const inputId = `input-${tag}`.replace(/\s+/g, '');

          const classNames: string[] = [styles.filterButtonText];
          if (currentFilter === tag) {
            classNames.push(styles.selected);
          }

          return (
            <label
              key={tag}
              htmlFor={inputId}
              className={clsx(classNames)}
              style={{
                //@ts-ignore - false positive
                '--transition-delay': (index + 1) * 0.15 + 's', // The multiplier value should be same as $animationDurationShort in _variables.scss
              }}
            >
              <input
                aria-checked={currentFilter === tag}
                className={styles.filterButton}
                onClick={() => setActiveFilter(tag)}
                type="radio"
                name="filter_option"
                id={inputId}
              />
              {tag}
            </label>
          );
        })}
      </div>
    </Section>
  );
}
