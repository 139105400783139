import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import DOMPurify from 'isomorphic-dompurify';
import Dialog from '@reach/dialog';
import { Builder } from '@builder.io/react/lite';
import { LocaleEnum } from '@store/enums';
import { ColorTextEnum } from '@store/types';
import { ProductMainProps } from './ProductMain.types';
import { useIsAlmondPage } from '@helpers/utils';
import { getBuilderPreviewProductsData } from '@helpers/getBuilderProductData';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import {
  IconHeartHealthy,
  IconNonGmo,
  IconPlantProtein,
  IconModal,
} from './ProductMainIcons';
import Section from '@design/Section/Section';
import NutritionLabel from '@modules/NutritionLabel/NutritionLabel';
import Button from '@common/Button/Button';
import styles from './ProductMain.module.scss';

export default function ProductMain(props: ProductMainProps) {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const [products, setProducts] = useState([
    { product: props.productDetails },
    { product: props.productDetailsLeft },
    { product: props.productDetailsRight },
  ]);

  const [isNutritionLabelOpened, setIsNutritionLabelOpened] = useState(false);

  const colorData = products[0].product?.value?.data
    .color as unknown as keyof typeof ColorTextEnum;
  const color = ColorTextEnum[colorData];

  const isAlmondPage = useIsAlmondPage();

  useEffect(() => {
    async function setPreviewData() {
      const mergedProducts = await getBuilderPreviewProductsData(
        [
          { product: props.productDetails },
          { product: props.productDetailsLeft },
          { product: props.productDetailsRight },
        ],
        locale as LocaleEnum
      );
      mergedProducts && setProducts(mergedProducts);
    }

    Builder.isPreviewing && setPreviewData();
  }, [
    props.productDetails,
    props.productDetailsLeft,
    props.productDetailsRight,
    locale,
  ]);

  useEffect(() => {
    // handle PDP theme colors
    if (color) {
      document.documentElement.style.setProperty(
        '--primary-theme-color',
        color
      );
      return () => {
        document.documentElement.style.setProperty(
          '--primary-theme-color',
          ColorTextEnum['none']
        );
      };
    }
  }, [color]);

  // handle transitioning between product pages
  const [isLoaded, setIsLoaded] = useState(false);
  const router = useRouter();
  useEffect(() => {
    setIsLoaded(true);

    const handleLoadedStateStart = () => setIsLoaded(false);
    const handleLoadedStateComplete = () => setIsLoaded(true);

    router.events.on('routeChangeStart', handleLoadedStateStart);
    router.events.on('routeChangeComplete', handleLoadedStateComplete);

    return () => {
      router.events.off('routeChangeStart', handleLoadedStateStart);
      router.events.off('routeChangeComplete', handleLoadedStateComplete);
    };
  }, [router.events]);

  // handle NFP clicks
  const dialogRef = useRef<HTMLDivElement>(null);

  if (!products[2]?.product?.value?.data)
    return <BuilderEditingHelper componentName="productMain" visible={true} />;

  const {
    name,
    description,
    image,
    imageAltText,
    heartHealthy,
    nonGmo,
    plantProtein,
    nutritionLabel,
    disclosure,
  } = products[0]?.product?.value.data;

  const productDetailCategory =
    products[0].product.value.data.productDetailCategory !== 'None'
      ? products[0].product.value.data.productDetailCategory
      : null;

  if (!products[2]?.product?.value?.data) return null;

  const prevProduct = products[1].product.value.data.productDetailUrl;
  const nextProduct = products[2].product.value.data.productDetailUrl;

  return (
    <Section
      data-testid={'product-details'}
      className={[
        'container',
        styles.productContainer,
        isLoaded ? styles.animateIn : styles.animateOut,
      ].join(' ')}
    >
      {nutritionLabel ? (
        <Dialog
          initialFocusRef={dialogRef}
          aria-label={`${name} - Nutrition label`}
          className={styles.nutritionLabel}
          isOpen={isNutritionLabelOpened}
          onDismiss={() => setIsNutritionLabelOpened(false)}
          ref={dialogRef}
          onClick={e => {
            if (e.target == dialogRef?.current) {
              setIsNutritionLabelOpened(false);
            }
          }}
        >
          <NutritionLabel
            isOpen={isNutritionLabelOpened}
            label={nutritionLabel}
            onClose={() => setIsNutritionLabelOpened(false)}
            dataTestid={'nutrition-label-popup'}
          />
        </Dialog>
      ) : null}
      <div className="row">
        {prevProduct && (
          <Link href={prevProduct}>
            <a
              className={locale === 'ar' ? styles.rightArrow : styles.leftArrow}
              aria-label="Previous product"
              data-testid={'prev-product'}
            >
              <span>{/* CSS arrows */}</span>
            </a>
          </Link>
        )}
        {/* Product Heading - Mobile Only */}
        <div
          className={clsx(
            'col-12',
            'col-lg-6',
            'd-flex',
            'd-lg-none',
            styles.productHeadingBlock
          )}
        >
          <h1 className={styles.productTitle}>{name}</h1>
          <span className={styles.productCategoryContainer}>
            Wonderful<sup>®</sup>&lrm;
            {isAlmondPage ? 'Almonds: ' : 'Pistachios: '}
            {productDetailCategory && (
              <Link href={`/${locale}/products`}>
                <a className={styles.productCategory}>
                  <span aria-hidden={true}>{t(productDetailCategory)}</span>
                </a>
              </Link>
            )}
          </span>
        </div>

        {/* Product Image */}
        <div
          className={['col-12 col-lg-6', styles.productImageContainer].join(
            ' '
          )}
        >
          {image && (
            <div className={styles.productImage}>
              <BuilderImage
                data-testid={'product-details-image'}
                imageSrc={image}
                mobileWidth={'200px'}
                tabletWidth={'300px'}
                alt={imageAltText || ''}
                disableLazyLoad={true}
              />
            </div>
          )}
        </div>

        {/* Product Details */}
        <div
          className={['col-12 col-lg-6', styles.productDetailsContainer].join(
            ' '
          )}
        >
          {/* Product Heading - Tablet/Desktop */}
          <div
            className={['d-none d-lg-flex', styles.productHeadingBlock].join(
              ' '
            )}
          >
            <h1>{name}</h1>
            <span className={styles.productCategoryContainer}>
              Wonderful<sup>®</sup>&lrm;
              {isAlmondPage ? ' Almonds: ' : ' Pistachios: '}
              {productDetailCategory && (
                <Link href={`/${locale}/products`}>
                  <a className={styles.productCategory}>
                    <span aria-hidden={true}>
                      {productDetailCategory && t(productDetailCategory)}
                    </span>
                  </a>
                </Link>
              )}
            </span>
          </div>

          {description && (
            <div
              className={styles.productDescription}
              data-testid={'product-details-description'}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              />
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              data-testid={'product-details-button'}
              as="Link"
              href={`/${locale}/where-to-buy`}
              design="round"
              className={styles.button}
            >
              {t('where to buy')}
            </Button>
          </div>
          {nutritionLabel && (
            <a
              className={styles.nutritionalFactsLink}
              tabIndex={0}
              onClick={() => setIsNutritionLabelOpened(true)}
              data-testid={'nutrition-toggle'}
            >
              {t('Nutrition Facts')}
              <IconModal />
            </a>
          )}

          {disclosure && (
            <ul className={styles.disclosures}>
              <li className={styles.disclosure} tabIndex={0}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(disclosure),
                  }}
                />
              </li>
            </ul>
          )}

          <div className={styles.iconsContainer}>
            {heartHealthy && <IconHeartHealthy />}
            {nonGmo && <IconNonGmo />}
            {plantProtein && <IconPlantProtein />}
          </div>
        </div>
        {nextProduct && (
          <Link href={nextProduct}>
            <a
              className={locale === 'ar' ? styles.leftArrow : styles.rightArrow}
              aria-label="Next product"
              data-testid={'next-product'}
            >
              <span>{/* CSS arrows */}</span>
            </a>
          </Link>
        )}
      </div>
    </Section>
  );
}
