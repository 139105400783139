import styles from './BuilderEditingHelper.module.scss';
import { Builder } from '@builder.io/react/lite';
import type { BuilderEditingHelperProps } from './BuilderEditingHelper.types';

export default function BuilderEditingHelper(props: BuilderEditingHelperProps) {
  const { componentName, helperText, visible } = props;

  return Builder.isEditing && visible ? (
    <div className={styles.builderEditingArea}>
      <p className={styles.body}>{componentName} Component</p>
      <br />
      {helperText ? (
        <span className={styles.helper}>({helperText})</span>
      ) : null}
    </div>
  ) : null;
}
