export enum LocaleEnum {
  EN_US = 'en-us',
  ES = 'es',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
  AR = 'ar',
}

export enum BuilderModels {
  PAGE = 'page',
  PAGE_ES = 'page-es',
  PAGE_DE = 'page-de',
  PAGE_FR = 'page-fr',
  PAGE_IT = 'page-it',
  PAGE_NL = 'page-nl',
  PAGE_AR = 'page-ar',
  PRODUCT_PAGE = 'product-page',
  PRODUCT_PAGE_ES = 'product-page-es',
  PRODUCT_PAGE_DE = 'product-page-de',
  PRODUCT_PAGE_FR = 'product-page-fr',
  PRODUCT_PAGE_IT = 'product-page-it',
  PRODUCT_PAGE_NL = 'product-page-nl',
  PRODUCT_PAGE_AR = 'product-page-ar',
}

export enum CustomBuilderComponents {
  CAMPAIGN_VIMEO_MODAL = 'CampaignVimeoModal',
  CONTAINER = 'Container',
  FILTERABLE_ACCORDION = 'FilterableAccordion',
  FILTERABLE_WHERE_TO_BUY = 'FilterableWhereToBuy',
  FIND_PISTACHIOS = 'FindPistachios',
  HEADING_BLOCK = 'HeadingBlock',
  HERO = 'Hero',
  INFOGRAPHIC = 'Infographic',
  PRODUCT_CAROUSEL = 'ProductCarousel',
  PRODUCT_LISTING = 'ProductListing',
  PRODUCT_MAIN = 'ProductMain',
  PRODUCT_SIZES = 'ProductSizes',
  RELATED_PRODUCTS = 'RelatedProducts',
  SPACER = 'Spacer',
  TEXT_BOX = 'TextBox',
  TWO_COLUMN_TABLE = 'TwoColumnTable',
  VIMEO_GALLERY = 'VimeoGallery',
  VOTE = 'Vote',
  WHO_WE_ARE = 'WhoWeAre',
}
