export enum HeadingLevel {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}
interface HeadingProps {
  children: React.ReactNode;
  headingLevel: HeadingLevel;
  classes?: string;
}

export default function Heading(props: HeadingProps) {
  const { headingLevel, children, classes } = props;
  const HeadingLevel = headingLevel || 'h1';

  return children ? (
    <HeadingLevel className={classes}>{children}</HeadingLevel>
  ) : null;
}
