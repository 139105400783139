// The values should be the same as the ones defined here: styles/_variables.scss

export const Breakpoints = {
  xxs: 375,
  xs: 480,
  maxSm: 575,
  sm: 576,
  maxMd: 767,
  md: 768,
  maxLg: 991,
  lg: 992,
  xl: 1200,
  xxl: 1500,
} as const;

export const Colors = {
  primary: '94cc27',
} as const;
