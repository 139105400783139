// import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';
import ProductMain from './ProductMain';

import config from '@helpers/config';
import { LocaleEnum, CustomBuilderComponents } from '@store/enums';

// const ProductMain = dynamic(async () => await import('./ProductMain'));

const localizedBuilderProductData = {
  [LocaleEnum.EN_US]: config.builderProductModelId,
  [LocaleEnum.ES]: config.builderProductModelIdSp,
  [LocaleEnum.DE]: config.builderProductModelIdDe,
  [LocaleEnum.FR]: config.builderProductModelIdFr,
  [LocaleEnum.IT]: config.builderProductModelIdIt,
  [LocaleEnum.NL]: config.builderProductModelIdNl,
  [LocaleEnum.AR]: config.builderProductModelIdAr,
};

const localesArr = [];
for (const [_key, value] of Object.entries(LocaleEnum)) {
  localesArr.push(value);
}

// set localizeRef for product data model
let localizedRefId = localizedBuilderProductData[LocaleEnum.EN_US];
// only run client side
if (typeof window !== 'undefined') {
  localesArr.forEach(locale => {
    if (window.location.pathname.indexOf(`/${locale}`) === 0) {
      localizedRefId = localizedBuilderProductData[locale];
      return;
    }
  });
}

Builder.registerComponent(ProductMain, {
  name: CustomBuilderComponents.PRODUCT_MAIN,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/details-more.svg',
  inputs: [
    {
      name: 'Admin Tips',
      type: 'adminTips',
      defaultValue:
        'Select product details you would like to show on this page',
    },
    {
      name: 'productDetails',
      type: 'reference',
      modelId: localizedRefId,
      required: true,
    },
    {
      name: 'productDetailsLeft',
      friendlyName: 'Previous Product',
      type: 'reference',
      modelId: localizedRefId,
      required: true,
    },
    {
      name: 'productDetailsRight',
      friendlyName: 'Next Product',
      type: 'reference',
      modelId: localizedRefId,
      required: true,
    },
  ],
});
