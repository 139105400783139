// import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';
import ProductListing from './ProductListing';

import config from '@helpers/config';
import { LocaleEnum, CustomBuilderComponents } from '@store/enums';

// const ProductListing = dynamic(async () => await import('./ProductListing'));

const localizedBuilderProductData = {
  [LocaleEnum.EN_US]: config.builderProductModelId,
  [LocaleEnum.ES]: config.builderProductModelIdSp,
  [LocaleEnum.DE]: config.builderProductModelIdDe,
  [LocaleEnum.FR]: config.builderProductModelIdFr,
  [LocaleEnum.IT]: config.builderProductModelIdIt,
  [LocaleEnum.NL]: config.builderProductModelIdNl,
  [LocaleEnum.AR]: config.builderProductModelIdAr,
};

const localesArr = [];
for (const [_key, value] of Object.entries(LocaleEnum)) {
  localesArr.push(value);
}

// set localizeRef for product data model
let localizedRefId = localizedBuilderProductData[LocaleEnum.EN_US];
// only run client side
if (typeof window !== 'undefined') {
  localesArr.forEach(locale => {
    if (window.location.pathname.indexOf(`/${locale}`) === 0) {
      localizedRefId = localizedBuilderProductData[locale];
      return;
    }
  });
}

Builder.registerComponent(ProductListing, {
  name: CustomBuilderComponents.PRODUCT_LISTING,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/stack.svg',
  inputs: [
    {
      name: 'isFilterEnabled',
      type: 'boolean',
      helperText:
        'Filter is automatically generated based off of product data values',
      defaultValue: true,
    },
    {
      name: 'products',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'product',
          type: 'reference',
          modelId: localizedRefId,
        },
      ],
    },
  ],
});
