import React, { useRef, useEffect } from 'react';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import styles from './Section.module.scss';

interface Props {
  first?: boolean;
  last?: boolean;
  noMargin?: boolean;
  className?: string;
  children: any;
  viewportThreshold?: number;
  htmlElement?: string;
  onEnterViewport?: () => void;
  onExitViewport?: () => void;
  onMouseMove?: () => void;
}

function useCombinedRefs<T>(
  ...refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
) {
  const targetRef = useRef<T>(null);
  useEffect(() => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

function Section(props: Props, ref: React.ForwardedRef<HTMLElement>) {
  const {
    first,
    last,
    noMargin,
    children,
    className,
    viewportThreshold,
    htmlElement = 'section',
    onEnterViewport,
    onExitViewport,
    onMouseMove,
    ...rest
  } = props;
  const local = useRef<HTMLElement>(null);
  const targetRef = useCombinedRefs<HTMLElement>(ref, local);

  useEffect(() => {
    const threshold = viewportThreshold || 0.25;
    if (
      !!window.IntersectionObserver &&
      targetRef.current &&
      (onEnterViewport || onExitViewport)
    ) {
      let observer = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio > threshold) {
            onEnterViewport && onEnterViewport();
          } else {
            onExitViewport && onExitViewport();
          }
        },
        {
          threshold: threshold || 0.5,
        }
      );
      observer.observe(targetRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [onEnterViewport, viewportThreshold, targetRef, onExitViewport]);

  return React.createElement(
    htmlElement,
    {
      ref: targetRef,
      className: clsx(
        styles.section,
        first && styles.first,
        last && styles.last,
        noMargin && styles.noMargin,
        className
      ),
      onMouseMove,
      ...rest,
    },
    children
  );
}

export default React.forwardRef(Section);
