// import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';
import FindPistachios from './FindPistachios';

import { buttonSubfields } from '@common/Button/Button.BuilderObj';
import { CustomBuilderComponents } from '@store/enums';

// const FindPistachios = dynamic(async () => await import('./FindPistachios'));

Builder.registerComponent(FindPistachios, {
  name: CustomBuilderComponents.FIND_PISTACHIOS,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/globe-alt.svg',
  inputs: [
    {
      name: 'eyebrow',
      type: 'string',
    },
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'body',
      type: 'customRichTextPistachios',
      required: true,
    },
    {
      name: 'button',
      friendlyName: 'Where to Buy Button',
      type: 'object',
      ...buttonSubfields,
    },
  ],
});
