export const buttonSubfields = {
  subFields: [
    {
      name: 'design',
      type: 'string',
      defaultValue: 'square',
      enum: ['square', 'round'],
    },
    {
      name: 'text',
      type: 'string',
    },
    {
      name: 'href',
      friendlyName: 'Link',
      type: 'url',
      helperText:
        'Url could be an internal relative link (e.g. `/contact`), external link (e.g. https://www.wonderful.com), an email (e.g. `mailto:name@domain.com`) or phone number (e.g. `tel:310-000-0000`).',
      regex: {
        // pattern to test, like "^\/[a-z]$"
        pattern: '^(/|http:|https:|mailto:|tel:)[\\w/?&=#-~.@]{2,}$',
        // flags for the RegExp constructor, e.g. "gi"  */
        options: 'i',
        // message to display to end-users if the regex fails
        message:
          'Invalid url format. Relative links should start with `/`. You could also use http/https links, mailto: and tel:',
      },
    },
  ],
};

export const buttonObj = {
  name: 'button',
  type: 'object',
  ...buttonSubfields,
};
