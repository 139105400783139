export enum EnvironmentEnum {
  production = 'production',
  stage = 'stage',
  development = 'development',
  local = 'local',
}

const processEnvs = {
  // Square bracket notation doesn't work on the Client: process.env['ENV_NAME'] (it works only on the Server)
  // It's because Next.js 9+ define variables using Webpack's DefinePlugin which doesn't allow destructuring:
  // https://github.com/vercel/next.js/issues/19420
  //
  // This is why we set Env name and value separately here:

  builderProductModelId: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID,
  },
  builderProductModelIdAr: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_AR',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_AR,
  },
  builderProductModelIdDe: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_DE',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_DE,
  },
  builderProductModelIdFr: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_FR',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_FR,
  },
  builderProductModelIdIt: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_IT',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_IT,
  },
  builderProductModelIdNl: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_NL',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_NL,
  },
  builderProductModelIdSp: {
    name: 'NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_SP',
    value: process.env.NEXT_PUBLIC_BUILDER_PRODUCT_MODEL_ID_SP,
  },
  builderApiKey: {
    name: 'NEXT_PUBLIC_BUILDER_API_KEY',
    value: process.env.NEXT_PUBLIC_BUILDER_API_KEY,
  },
  contactFormEndpoint: {
    name: 'NEXT_PUBLIC_CONTACT_FORM_ENDPOINT',
    value: process.env.NEXT_PUBLIC_CONTACT_FORM_ENDPOINT,
  },
  contactFormRoutingStatus: {
    name: 'NEXT_PUBLIC_CONTACT_FORM_ROUTING_STATUS',
    value: process.env.NEXT_PUBLIC_CONTACT_FORM_ROUTING_STATUS,
  },
  pollApiKey: {
    name: 'NEXT_PUBLIC_POLL_API_KEY',
    value: process.env.NEXT_PUBLIC_POLL_API_KEY,
  },
  webUrl: {
    name: 'NEXT_PUBLIC_WEB_URL',
    value: process.env.NEXT_PUBLIC_WEB_URL,
  },
  env: {
    name: 'NEXT_PUBLIC_ENVIRONMENT',
    value: process.env.NEXT_PUBLIC_ENVIRONMENT as EnvironmentEnum,
  },
  GtmId: { name: 'NEXT_PUBLIC_GTM_ID', value: process.env.NEXT_PUBLIC_GTM_ID },
  oneTrustId: {
    name: 'NEXT_PUBLIC_ONE_TRUST_SCRIPT_ID',
    value: process.env.NEXT_PUBLIC_ONE_TRUST_SCRIPT_ID,
  },
};
type GenericObjectType = { [key: string]: string };

const getAllEnvs = (envs: typeof processEnvs) => {
  const allEnvs: GenericObjectType = {};
  const allUndefinedEnvs: string[] = [];
  let key: keyof typeof envs;
  for (key in envs) {
    const value = envs[key].value;
    if (value) {
      allEnvs[key] = value;
    } else {
      allUndefinedEnvs.push(envs[key].name);
    }
  }

  if (allUndefinedEnvs.length > 0 && !process.env.STORYBOOK) {
    throw new Error(
      `Couldn't find environment variable(s): ${allUndefinedEnvs}`
    );
  } else {
    return allEnvs as { [key in keyof typeof processEnvs]: string };
  }
};

const editableConfig = {
  cacheTTL: Number(process.env.CACHE_TTL) || 60, // We don't use processEnvs here because only NEXT_PUBLIC_X envs are available on the Client (it will throw an Error, but we only use this TTL on the Server)
  builderApiUrl: 'https://cdn.builder.io/api/v3/content',
  ...getAllEnvs(processEnvs),
};

const config = Object.freeze({
  ...editableConfig,
  env: editableConfig.env as EnvironmentEnum,
  isEnvOtherThanProd:
    editableConfig.env === EnvironmentEnum.development ||
    editableConfig.env === EnvironmentEnum.stage ||
    editableConfig.env === EnvironmentEnum.local,
});

export default config;
