export type BuilderReferenceType = {
  '@type': string;
  id: string;
  model: string;
};

export type SingleProductType = {
  product: BuilderProductReference;
};

export type StickerEnum = 'none' | 'Best-Seller' | 'New Flavor';

export type ProductDetailCategoryEnum =
  | 'None'
  | 'In-Shell'
  | 'No Shells'
  | 'Almonds'
  | 'Flavours';

export enum ColorTextEnum {
  none = '#94CC27',
  'Roasted Salted' = '#94CC27',
  'Roasted Salted No Shells' = '#94CC00',
  'No Salt' = '#6BC3E9',
  'Lightly Salted' = '#FCE835',
  'Lightly Salted No shells' = '#AABCC2',
  'Salt Pepper' = '#B0A5CD',
  'Sweet Chili' = '#DB3345',
  'Chili Roasted No Shells' = '#EB4F3F',
  'Honey Roasted' = '#E39F3E',
  'Salt Vinegar' = '#1FA39C',
  'Backyard BBQ' = '#D9670F',
  'Backyard BBQ Alt' = '#DC7C31',
  'Blanched Almond' = '#B70046',
}

export type ProductType = {
  id: string;
  name: string;
  description: string;
  shortDescription?: string | null | undefined;
  url: {
    id: string;
  };
  image: string;
  imageAltText: string | null;
  productDetailUrl: string;
  shell: boolean;
  almonds: boolean;
  flavours: boolean;
  sticker?: StickerEnum;
  heartHealthy: boolean;
  nonGmo: boolean;
  plantProtein: boolean;
  color: ColorTextEnum;
  productDetailCategory: ProductDetailCategoryEnum;
  nutritionLabel: string;
  disclosure: string;
};

export interface BuilderProductReference extends BuilderReferenceType {
  value: { data: ProductType; name: string };
}
