import dynamic from 'next/dynamic';
import styles from './layout.module.scss';

const Header = dynamic(() => import('@static/Header/Header'));
const Footer = dynamic(() => import('@static/Footer/Footer'));

const Layout: React.FC<{}> = ({ children }) => {
  // To fetch data here we can use SWR: https://nextjs.org/docs/basic-features/layouts#data-fetching
  return (
    <>
      <Header />
      <main className={styles.mainContent}>{children}</main>
      <Footer />
    </>
  );
};

export function getDefaultLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
}

export default Layout;
