import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

import { CustomBuilderComponents } from '@store/enums';

const Hero = dynamic(async () => await import('./Hero'));

Builder.registerComponent(Hero, {
  name: CustomBuilderComponents.HERO,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/image.svg',
  inputs: [
    {
      name: 'headline',
      type: 'object',
      subFields: [
        {
          name: 'topheading',
          friendlyName: 'Top Heading',
          type: 'string',
        },
        {
          name: 'heading',
          type: 'string',
        },
        {
          name: 'subheading',
          type: 'string',
        },
        {
          name: 'switchHeadingStyles',
          friendlyName: 'Change Heading Styles',
          helperText:
            'Switches the Style order of the Heading and Subheading fields',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
    {
      name: 'image',
      type: 'object',
      subFields: [
        {
          name: 'src',
          friendlyName: 'Image source',
          type: 'file',
          required: true,
          allowedFileTypes: ['jpeg', 'png'],
        },
        {
          name: 'altText',
          friendlyName: 'Image description text',
          type: 'string',
        },
      ],
    },
  ],
});
