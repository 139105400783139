import { useState, useEffect } from 'react';
import type { CSSProperties } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import DOMPurify from 'isomorphic-dompurify';
import { useTranslation } from 'next-i18next';
import { Builder } from '@builder.io/react/lite';
import { getBuilderPreviewProductsData } from '@helpers/getBuilderProductData';
import { LocaleEnum } from '@store/enums';
import { SingleProductType } from '@store/types';
import {
  RelatedProductsProps,
  Pair,
  PistachioBagProps,
} from './RelatedProducts.types';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import Section from '@design/Section/Section';
import styles from './RelatedProducts.module.scss';

export default function RelatedProducts(props: RelatedProductsProps) {
  const [isOn, setIsOn] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const [productsCollection, setProductsCollection] = useState([
    props.firstProductsCollection,
    props.secondProductsCollection,
  ]);

  const { title, subtitle, defaultCollectionName, secondaryCollectionName } =
    props;

  useEffect(() => {
    async function setPreviewData() {
      const mergedProductsOne = await getBuilderPreviewProductsData(
        props.firstProductsCollection,
        locale as LocaleEnum
      );

      const mergedProductsTwo = await getBuilderPreviewProductsData(
        props.secondProductsCollection,
        locale as LocaleEnum
      );

      if (mergedProductsOne && mergedProductsTwo) {
        setProductsCollection([mergedProductsOne, mergedProductsTwo]);
      }
    }

    Builder.isPreviewing && setPreviewData();
  }, [props.firstProductsCollection, props.secondProductsCollection, locale]);

  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  const switchRowContents = (
    <>
      <button
        className={clsx(styles.switchButton, isOn ? '' : styles.greenText)}
        onClick={() => setIsOn(false)}
        aria-label={defaultCollectionName + ` related products.`}
      >
        {t(defaultCollectionName)}
      </button>

      <Switch
        data-testid={'related-switch'}
        isOn={isOn}
        toggle={() => setIsOn(on => !on)}
        defaultToggleLabel={defaultCollectionName}
        secondToggleLabel={secondaryCollectionName}
      />

      <button
        className={clsx(styles.switchButton, isOn ? styles.greenText : '')}
        onClick={() => setIsOn(true)}
        aria-label={secondaryCollectionName + ` related products.`}
      >
        {t(secondaryCollectionName)}
      </button>
    </>
  );

  const pairs = collectionsToOrganizedPairs(
    productsCollection[0],
    productsCollection[1]
  );

  if (!pairs.length) {
    return (
      <BuilderEditingHelper componentName="RelatedProducts" visible={true} />
    );
  }

  return (
    <Section
      className={clsx(
        styles.relatedProducts,
        isInViewport ? styles.isInViewport : ''
      )}
      onEnterViewport={onEnterViewport}
      data-testid="related-products"
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && (
        <div
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }}
          data-testid="related-subtitle"
        />
      )}
      <div className={styles.mobileSwitchRow}>{switchRowContents}</div>
      <ul className={styles.pistachioBags} data-testid="related-product-list">
        {pairs?.map((pair, index) => (
          <PistachioBag
            key={`${pair.id}_${index}`}
            {...pair}
            switchTurnedOn={isOn}
            flipAnimationIndex={isOn ? index : pairs.length - index - 1}
            index={index}
          />
        ))}
      </ul>
    </Section>
  );
}

// this function lets us reorganize the products in the API response to make sure
// pairs match up
function collectionsToOrganizedPairs(
  array1: SingleProductType[],
  array2: SingleProductType[]
): Pair[] {
  const firstProductsArray = array1;
  const secondProductsArray = array2;
  const pairs: Pair[] = [];

  if (
    firstProductsArray !== undefined &&
    firstProductsArray.length &&
    secondProductsArray !== undefined &&
    secondProductsArray.length
  ) {
    for (
      let i = 0;
      i < firstProductsArray?.length && i < secondProductsArray?.length;
      i++
    ) {
      pairs.push({
        id: `${firstProductsArray[i]?.product?.id}_${secondProductsArray[i]?.product?.id}`,
        frontItem: firstProductsArray[i],
        backItem: secondProductsArray[i],
      });
    }
  }

  return pairs;
}

function Switch(props: {
  isOn: boolean;
  toggle: () => void;
  defaultToggleLabel: string;
  secondToggleLabel: string;
  'data-testid'?: string | undefined;
}) {
  const classNames = [styles.switch];

  if (props.isOn) {
    classNames.push(styles.isOn);
  }

  return (
    <button
      data-testid={props['data-testid'] ?? props['data-testid']}
      aria-label={`${
        props.isOn ? props.secondToggleLabel : props.defaultToggleLabel
      } related products selected. Switch to see ${
        props.isOn ? props.defaultToggleLabel : props.secondToggleLabel
      } related products.`}
      className={clsx(classNames)}
      onClick={() => {
        props.toggle();
      }}
    />
  );
}

function PistachioBag(props: PistachioBagProps) {
  const [classes, setClasses] = useState([
    styles.pistachioBagContainer,
    ...(props.switchTurnedOn ? [styles.reversed] : []),
  ]);

  useEffect(() => {
    setClasses([
      styles.pistachioBagContainer,
      ...(props.switchTurnedOn ? [styles.reversed] : []),
    ]);
  }, [props.switchTurnedOn]);

  const onFocus = () => {
    setClasses([...classes, styles.active]);
  };

  const onBlur = () => {
    const newClasses = [...classes];
    newClasses.pop();
    setClasses(newClasses);
  };

  const { frontItem, backItem } = props;

  const frontItemName = frontItem.product?.value?.data?.name;

  const frontItemUrl = frontItem.product?.value?.data?.productDetailUrl;

  const frontItemImage = frontItem.product?.value?.data?.image;

  const frontItemImageAltText = frontItem.product?.value?.data?.imageAltText;

  const frontItemDescription =
    frontItem.product?.value?.data?.shortDescription ?? '';

  const frontItemSticker =
    frontItem.product?.value?.data?.sticker &&
    frontItem.product?.value?.data?.sticker !== 'none'
      ? frontItem.product?.value?.data?.sticker
      : null;

  const backItemName = backItem.product?.value?.data?.name;

  const backItemUrl = backItem.product?.value?.data?.productDetailUrl;

  const backItemImage = backItem.product?.value?.data?.image;

  const backItemImageAltText = backItem.product?.value?.data?.imageAltText;

  const backItemDescription =
    backItem.product?.value?.data?.shortDescription ?? '';

  const backItemSticker =
    backItem.product?.value?.data?.sticker &&
    backItem.product?.value?.data?.sticker !== 'none'
      ? backItem.product?.value?.data?.sticker
      : null;

  return (
    <li
      className={clsx(classes)}
      style={
        {
          '--flip-delay': `${props.flipAnimationIndex * 70}ms`,
          '--transition-delay': `${(props.index + 1) * 0.15}s`, // The multiplier value should be same as $animationDurationShort in _variables.scss
        } as CSSProperties
      }
    >
      {!props.switchTurnedOn && frontItemUrl ? (
        <Link href={`${frontItemUrl}`}>
          <a onFocus={onFocus} onBlur={onBlur} className={styles.productLink}>
            {frontItemName}
          </a>
        </Link>
      ) : backItemUrl ? (
        <Link href={`${backItemUrl}`}>
          <a onFocus={onFocus} onBlur={onBlur} className={styles.productLink}>
            {backItemName}
          </a>
        </Link>
      ) : null}

      <div className={styles.pistachioBagNameContainer}>
        <Crossfade
          data-testid="related-name"
          switchTurnedOn={props.switchTurnedOn}
          front={
            <span
              className={styles.pistachioBagName}
              aria-hidden={props.switchTurnedOn}
            >
              {frontItemName}
            </span>
          }
          back={
            <span
              className={styles.pistachioBagName}
              aria-hidden={!props.switchTurnedOn}
            >
              {backItemName}
            </span>
          }
        />
      </div>

      <div className={styles.bagImagesWrapper} data-testid={'related-images'}>
        <div
          className={styles.bagImageWrapper}
          aria-hidden={props.switchTurnedOn}
        >
          <BuilderImage
            imageSrc={frontItemImage}
            mobileWidth={'120px'}
            tabletWidth={'140px'}
            desktopWidth={'200px'}
            alt={frontItemImageAltText || ''}
          />
          {frontItemSticker && (
            <label className={styles.bestSellerIcon}>{frontItemSticker}</label>
          )}
        </div>
        <div
          className={`${styles.bagImageWrapper} ${styles.backside}`}
          aria-hidden={!props.switchTurnedOn}
        >
          <BuilderImage
            imageSrc={backItemImage}
            mobileWidth={'120px'}
            tabletWidth={'140px'}
            desktopWidth={'200px'}
            alt={backItemImageAltText || ''}
          />
          {backItemSticker && (
            <label className={styles.bestSellerIcon}>{backItemSticker}</label>
          )}
        </div>
      </div>
    </li>
  );
}

// a component that renders both of its children in the same place on top of each other,
// while keeping one hidden with opacity: 0. It always takes up as much height as
// the larger child, so that no layout shift occurs between swaps.
function Crossfade(props: {
  front: JSX.Element;
  back: JSX.Element;
  switchTurnedOn: boolean;
  'data-testid'?: string | undefined;
}) {
  return (
    <div
      className={styles.crossfadeWrapper}
      data-testid={props['data-testid'] ?? props['data-testid']}
    >
      <div
        className={styles.crossfadeItemWrapper}
        data-active={`${!props.switchTurnedOn}`}
      >
        {props.front}
      </div>
      <div
        className={`${styles.crossfadeItemWrapper} ${styles.backside}`}
        data-active={`${props.switchTurnedOn}`}
      >
        {props.back}
      </div>
    </div>
  );
}
