// This is a basic example of building and registering a builder.io component

import { Builder } from '@builder.io/react/lite';
import Heading from './Heading';

Builder.registerComponent(Heading, {
  name: 'Heading',
  inputs: [
    {
      name: 'heading',
      type: 'string',
    },
    {
      name: 'headingLevel',
      type: 'string',
      defaultValue: 'h1',
      enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
  ],
});
