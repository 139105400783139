import { SingleProductType } from '@store/types';
import { LocaleEnum } from '@store/enums';

export async function getBuilderModelData(
  id: string,
  locale: LocaleEnum,
  modelPath: string
) {
  // reference product data with locale
  const builderLocalizedModelPath =
    locale === LocaleEnum.EN_US ? modelPath : `${modelPath}-${locale}`;
  const BUILDER_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_BUILDER_API_KEY;

  const responseBuilder = await fetch(
    `https://cdn.builder.io/api/v3/content/${builderLocalizedModelPath}?apiKey=${BUILDER_PUBLIC_API_KEY}&query.id=${id}`
  ).then(res => res.json());
  return responseBuilder.results;
}

export async function getSelectedProductPageRefs(
  products: SingleProductType[],
  locale: LocaleEnum
) {
  let productsDataArr: Promise<SingleProductType>[];

  if (typeof products !== 'undefined') {
    return (productsDataArr = await Promise.all(
      products.map(async (productsItem: SingleProductType) => {
        if (productsItem.product && productsItem.product.value?.data.url.id) {
          const productResponseData = await getBuilderModelData(
            productsItem.product.value.data.url.id,
            locale,
            'product-page'
          );
          return productResponseData[0];
        }
      })
    ));
  }
}

export async function getPageProductsData(
  products: SingleProductType[],
  locale: LocaleEnum
) {
  // queries all product-page references in product list

  const productCarouselDataArr = await getSelectedProductPageRefs(
    products,
    locale
  );

  products?.forEach((productObj: SingleProductType) => {
    // for each product item in list, add a 'productDetailUrl' property from the product reference 'url' value
    productCarouselDataArr?.forEach(productCarouselDataArrObj => {
      if (
        productObj.product?.value?.data.url.id === productCarouselDataArrObj?.id
      ) {
        if (productCarouselDataArrObj != undefined) {
          productObj.product.value.data.productDetailUrl =
            productCarouselDataArrObj.data.url;
        }
      }
    });
  });

  return products;
}

export async function getSelectedProductData(
  products: SingleProductType[],
  locale: LocaleEnum
) {
  let productsDataArr: Promise<SingleProductType>[];

  if (typeof products !== 'undefined') {
    return (productsDataArr = await Promise.all(
      products.map(async (productsItem: SingleProductType) => {
        if (productsItem.product && productsItem.product.id) {
          const productResponseData = await getBuilderModelData(
            productsItem.product.id,
            locale,
            'product-data'
          );
          return productResponseData[0];
        }
      })
    ));
  }
}

export async function getBuilderPreviewProductsData(
  products: SingleProductType[],
  locale: LocaleEnum
) {
  // queries product-data from product reference 'id'
  const productCarouselDataArr = await getSelectedProductData(products, locale);

  products?.forEach((productObj: SingleProductType) => {
    // for each product item in list, add the product's data to a 'value' object property
    productCarouselDataArr?.forEach(productCarouselDataArrObj => {
      if (productObj.product?.id === productCarouselDataArrObj?.id) {
        if (productObj.product !== undefined) {
          productObj.product.value = productCarouselDataArrObj;
        }
      }
    });
  });

  // provides 'productDetailUrl' properties to each product item in list
  return await getPageProductsData(products, locale);
}
