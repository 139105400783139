import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';

import { isExternalLink } from '@helpers/utils';

import styles from './Button.module.scss';
import type { buttonDesignTypes } from './Button.types';

export default function Button(
  props: (
    | ({ as: 'button' } & ButtonHTMLAttributes<HTMLButtonElement>)
    | ({ as: 'a' } & AnchorHTMLAttributes<HTMLAnchorElement>)
    | ({ as: 'Link' } & Pick<
        LinkProps,
        'href' | 'scroll' | 'replace' | 'shallow'
      > &
        AnchorHTMLAttributes<HTMLAnchorElement>)
  ) & {
    design?: buttonDesignTypes;
    disabled?: boolean;
    size?: 'small';
    noPadding?: boolean;
  }
) {
  const { className, design, as, children, size, noPadding, ...rest } = props;
  const classNames = clsx(
    styles.buttonElement,
    design,
    size && styles.small,
    noPadding && styles.noPadding,
    className
  );

  // handle the case where "Link" or "a" is used for external links (anything starting with http/https)
  const isExternal =
    (as === 'Link' || as === 'a') && props.href && isExternalLink(props.href)
      ? true
      : false;

  if (as === 'button') {
    return (
      <button
        className={classNames}
        {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        <SpanItems design={design} buttonText={children} />
      </button>
    );
  } else if (as === 'a' || isExternal) {
    return (
      <a
        target={isExternal ? '_blank' : '_self'}
        rel={isExternal ? 'noopener' : ''}
        className={classNames}
        {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        <SpanItems design={design} buttonText={children} />
      </a>
    );
  } else if (as === 'Link') {
    const { href, scroll, replace, shallow, design, as, noPadding, ...rest } =
      props;
    return (
      <Link href={href} scroll={scroll} replace={replace} shallow={shallow}>
        <a {...rest} className={classNames}>
          <SpanItems design={design} buttonText={children} />
        </a>
      </Link>
    );
  } else {
    throw new Error(
      "<Button /> must be passed an 'as' prop of 'button', 'a', or 'Link'"
    );
  }
}

// Used to create dynamically sized round button
function SpanItems(props: {
  design?: buttonDesignTypes;
  buttonText?: ReactNode;
}) {
  const { design, buttonText } = props;
  return (
    <>
      {design === 'round' ? (
        <>
          <span className={styles.semiCircleLeft}></span>
          <span className={styles.buttonText}>{buttonText}</span>
          <span className={styles.semiCircleRight}></span>
        </>
      ) : (
        <span>{buttonText}</span>
      )}
    </>
  );
}
