import { Builder, builder } from '@builder.io/react/lite'; // lite version without build in components.
// If you would like to use any build in Builder component you have to import it manually like this:
// import '@builder.io/react/dist/lib/src/blocks/Button';
// import '@builder.io/react/dist/lib/src/blocks/Columns';

import { BuilderModels, CustomBuilderComponents } from '@store/enums';

import './TextBox/TextBox.builder';
import './TwoColumnTable/TwoColumnTable.builder';
import './Container/Container.builder';
import '../common/Heading/Heading.builder';
import './Spacer/Spacer.builder';
import './ProductCarousel/ProductCarousel.builder';
import './ProductListing/ProductListing.builder';
import './ProductSizes/ProductSizes.builder';
import './ProductMain/ProductMain.builder';
import './RelatedProducts/RelatedProducts.builder';
import './FindPistachios/FindPistachios.builder';
import './HeadingBlock/HeadingBlock.builder';
import './Hero/Hero.builder';
import './FilterableWhereToBuy/FilterableWhereToBuy.builder';
import './FilterableAccordion/FilterableAccordion.builder';
import './WhoWeAre/WhoWeAre.builder';
import './CampaignVimeoModal/CampaignVimeoModal.builder';
import './VimeoGallery/VimeoGallery.builder';
import './Infographic/Infographic.builder';
import './Vote/Vote.builder';

// RECOMMENDED WHEN USING BUILDER LITE !
// use this to hide all default built-in components and fully manage
// the insert menu components and sections yourself
Builder.register('editor.settings', {
  customInsertMenu: true,
});

type Item = {
  name: string;
  exclude?: Array<string>;
};

type BuilderCustomComponents = {
  name: string;
  items: Item[];
  allowedModels: Array<string>;
};

const productPageModels = [
  BuilderModels.PRODUCT_PAGE,
  BuilderModels.PRODUCT_PAGE_ES,
  BuilderModels.PRODUCT_PAGE_DE,
  BuilderModels.PRODUCT_PAGE_FR,
  BuilderModels.PRODUCT_PAGE_IT,
  BuilderModels.PRODUCT_PAGE_NL,
  BuilderModels.PRODUCT_PAGE_AR,
];

const pageLanguageModels = [
  BuilderModels.PAGE,
  BuilderModels.PAGE_ES,
  BuilderModels.PAGE_DE,
  BuilderModels.PAGE_FR,
  BuilderModels.PAGE_IT,
  BuilderModels.PAGE_NL,
  BuilderModels.PAGE_AR,
];

const generalComponents: BuilderCustomComponents = {
  name: 'General Components',
  items: [
    { name: CustomBuilderComponents.CAMPAIGN_VIMEO_MODAL },
    { name: CustomBuilderComponents.FILTERABLE_ACCORDION },
    { name: CustomBuilderComponents.FILTERABLE_WHERE_TO_BUY },
    { name: CustomBuilderComponents.FIND_PISTACHIOS },
    { name: CustomBuilderComponents.HEADING_BLOCK },
    { name: CustomBuilderComponents.HERO },
    { name: CustomBuilderComponents.INFOGRAPHIC },
    { name: CustomBuilderComponents.PRODUCT_CAROUSEL },
    { name: CustomBuilderComponents.PRODUCT_LISTING },
    { name: CustomBuilderComponents.TEXT_BOX },
    { name: CustomBuilderComponents.VIMEO_GALLERY },
    { name: CustomBuilderComponents.VOTE },
    { name: CustomBuilderComponents.WHO_WE_ARE },
  ],
  allowedModels: pageLanguageModels,
};

const layoutComponents: BuilderCustomComponents = {
  name: 'Layout Components',
  items: [
    { name: CustomBuilderComponents.CONTAINER },
    { name: CustomBuilderComponents.SPACER },
    { name: CustomBuilderComponents.TWO_COLUMN_TABLE },
  ],
  allowedModels: pageLanguageModels,
};

const productComponents: BuilderCustomComponents = {
  name: 'Product Components',
  items: [
    { name: CustomBuilderComponents.PRODUCT_MAIN },
    { name: CustomBuilderComponents.PRODUCT_SIZES },
    { name: CustomBuilderComponents.RELATED_PRODUCTS },
  ],
  allowedModels: productPageModels,
};

const components: BuilderCustomComponents[] = [
  generalComponents,
  layoutComponents,
  productComponents,
];

components.forEach(component => {
  if (component.allowedModels.includes(builder.editingModel!)) {
    Builder.register('insertMenu', {
      name: component.name,
      items: component.items.filter(({ name, exclude }) => {
        if (!exclude?.includes(builder.editingModel!)) {
          return { name };
        }
      }),
    });
  }
});
