import {
  DataLayerEventEnums,
  RouterDataLayerTypes,
} from './googleTagManager.types';

export function pushToDataLayer(
  event: DataLayerEventEnums,
  data: RouterDataLayerTypes
) {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }

  switch (event) {
    case DataLayerEventEnums.PAGEVIEW:
      window.dataLayer.push({
        event: DataLayerEventEnums.PAGEVIEW,
        ...data,
      });
      break;
    default:
      console.warn('Data Layer Event Enum undefined');
  }
}
