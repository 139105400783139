import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import { ContainerProps } from './Container.types';

export const childComponents = ['TextBox', 'TwoColumnTable'];

export const childComponentsAsString = childComponents
  .toString()
  .replace(/,/g, ', ');

export default function Container({ children, id }: ContainerProps) {
  const isVisible =
    !children || (Array.isArray(children) && children?.length === 0);

  return (
    <div id={id}>
      <BuilderEditingHelper
        componentName="Container"
        helperText={`Drag [${childComponentsAsString}] component${
          childComponents.length > 1 ? 's' : ''
        } from the Insert menu and drop it here to add it as a child`}
        visible={isVisible}
      />
      {/* nested child components */}
      {children && children}
    </div>
  );
}
