import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

import { CustomBuilderComponents } from '@store/enums';

const Spacer = dynamic(async () => await import('./Spacer'));

Builder.registerComponent(Spacer, {
  name: CustomBuilderComponents.SPACER,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/space-between-v.svg',
  inputs: [
    {
      name: 'Admin Tips',
      type: 'adminTips',
      defaultValue: `
      <strong>mobile</strong> - 60px<br />
      <strong>tablet</strong> - 60px<br />
      <strong>desktop</strong> - 60px
      `,
    },
  ],
});
