import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import { TFunction, useTranslation } from 'next-i18next';
import DOMPurify from 'isomorphic-dompurify';
import { Builder } from '@builder.io/react/lite';
import { getBuilderPreviewProductsData } from '@helpers/getBuilderProductData';
import { cleanOfWidowedWords } from '@helpers/utils';
import { LocaleEnum } from '@store/enums';
import { SingleProductType } from '@store/types';
import { ProductListingModule } from './ProductListing.types';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import Section from '@design/Section/Section';
import Filter from '@modules/Filter/Filter';
import styles from './ProductListing.module.scss';

const filters = {
  all: () => true,
  noShells: (product: SingleProductType) =>
    !product.product.value.data.shell && !product.product.value.data.almonds,
  inShell: (product: SingleProductType) => !!product.product.value.data.shell,
  almonds: (product: SingleProductType) => !!product.product.value.data.almonds,
  flavours: (product: SingleProductType) =>
    !!product.product.value.data.flavours,
};

type FilterKey = keyof typeof filters;

export default function ProductListing(props: ProductListingModule) {
  const { isFilterEnabled, builderBlock } = props;
  const [isAnimating, setIsAnimating] = useState(false);
  const [removeTimeOut, setRemoveTimeOut] = useState(true);
  const router = useRouter();

  const [products, setProducts] = useState(props.products);
  const { locale } = useRouter();

  const { t } = useTranslation('common');
  const [activeFilter, setActiveFilter] = useState<FilterKey>();
  const componentId = builderBlock?.id;

  useEffect(() => {
    async function setPreviewData() {
      const mergedProducts = await getBuilderPreviewProductsData(
        props.products,
        locale as LocaleEnum
      );
      mergedProducts && setProducts(mergedProducts);
    }

    Builder.isPreviewing && setPreviewData();
  }, [props.products, locale]);

  const productDataExists =
    typeof products !== 'undefined' && products.length > 0;

  const productTags: string[] = [];

  if (products) {
    products.forEach(product => {
      if (product.product?.value?.data.shell) {
        productTags.push('inShell');
      } else {
        productTags.push('noShells');
      }
      if (product.product?.value?.data.almonds) {
        productTags.push('almonds');
      }
      if (product.product?.value?.data.flavours) {
        productTags.push('flavours');
      }
    });
  }

  const sort_reference = ['noShells', 'inShell', 'almonds', 'flavours'];

  const filterTags = Array.from(new Set(productTags));
  filterTags.sort(function (a, b) {
    return sort_reference.indexOf(a) - sort_reference.indexOf(b);
  });

  const productFiltersToText = {
    all: t('all'),
    noShells: t('no shells'),
    inShell: t('in-shell'),
    almonds: t('almonds'),
    flavours: t('flavours'),
  };

  type ProductFiltersKey = keyof typeof productFiltersToText;
  const filterTagsTranslated: string[] = filterTags.map(val => {
    return productFiltersToText[val as ProductFiltersKey];
  });

  // don't move things around if the user prefers reduced motion
  const productsListRef = useRef<HTMLUListElement>(null);
  const transitionDelay = 0.075;
  useEffect(() => {
    if (isAnimating === true) {
      const productList = productsListRef.current;
      if (productList && productList.getAttribute('data-animating')) {
        const productListItem = productList.querySelectorAll('[aria-hidden]');
        const numberOfElementsToRemove = Number(
          productList.getAttribute('data-list-length') || '0'
        );
        // number of visible elements
        const productListVisibleEles = productList.querySelectorAll(
          '[aria-hidden=false]'
        ).length;
        productList.setAttribute(
          'data-list-length',
          productListVisibleEles.toString()
        );

        // remove timeout for first load
        const timeoutDelay = removeTimeOut
          ? 150
          : numberOfElementsToRemove * transitionDelay * 1.5 * 1000;

        // timeout to wait for the animation of item hiding
        setTimeout(function () {
          const productListItemVisible =
            productList.querySelectorAll<HTMLElement>('li[aria-hidden=false]');

          // set incremented transition delays for visible elements
          productListItemVisible.forEach(function (listItemVisible, index) {
            listItemVisible.style.setProperty(
              '--transition-delay',
              `${index * transitionDelay}s`
            );
          });

          // update all elements visible state based on it's aria-hidden status
          productListItem.forEach(function (listItem) {
            if (listItem.getAttribute('aria-hidden') == 'false') {
              listItem.classList.add('visible');
              listItem.classList.remove('hidden');
            } else {
              listItem.classList.add('hidden');
              listItem.classList.remove('visible');
            }
          });

          setIsAnimating(false);
          setRemoveTimeOut(false);
        }, timeoutDelay);
      }
    }
  }, [isAnimating, removeTimeOut, props.products]);

  const filterQuery = router.query?.filter;
  // update filter query param on change of filters
  useEffect(() => {
    if (activeFilter) {
      setIsAnimating(true);
      const updateQueryParam = (newFilter: FilterKey) => {
        const newRoute = {
          pathname: router.pathname,
          query: { ...router.query, filter: newFilter },
        };
        router.replace(newRoute, undefined, { shallow: true, scroll: false });
      };

      updateQueryParam(activeFilter as FilterKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]); // router dependency is not needed

  // set active filter on filterQuery change
  useEffect(() => {
    setActiveFilter(filterQuery as FilterKey);
  }, [filterQuery]);

  // start animation when user enters page
  useEffect(() => {
    setIsAnimating(true);
    setRemoveTimeOut(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // ignore eslint, only needs to fire

  // this check must be after useEffects
  if (!products) {
    return (
      <BuilderEditingHelper
        componentName="ProductListing"
        visible={!productDataExists}
      />
    );
  }

  return (
    <Section htmlElement="div" className={clsx(styles.outerSection)}>
      <meta itemProp="name" content="Wonderful Pistachios" />
      {isFilterEnabled && (
        <Filter
          tags={filterTagsTranslated}
          currentFilter={activeFilter || t('all')}
          componentId={componentId}
          ariaLabel="Product filters"
          setActiveFilter={setActiveFilter as (value: string) => void}
          data-testid={'product-filter'}
          classes={styles.productFilter}
        />
      )}
      <ul
        className={styles.productsList}
        aria-live="polite"
        aria-relevant="additions removals"
        ref={productsListRef}
        data-animating={isAnimating}
        data-testid="product-list"
      >
        {products?.map((product, index) => (
          <ListItem
            product={product}
            key={product.product?.id}
            selectedFilter={
              (activeFilter &&
                (filterTags[
                  filterTagsTranslated.indexOf(activeFilter)
                ] as FilterKey)) ||
              'all'
            }
            index={index}
            t={t}
          />
        ))}
      </ul>
    </Section>
  );
}

function ListItem(props: {
  product: SingleProductType;
  selectedFilter: FilterKey;
  index: number;
  t: TFunction;
}) {
  const [isActive, setIsActive] = useState(false);

  if (!props.product.product?.value) {
    return null;
  }

  const {
    name,
    shell,
    almonds,
    shortDescription,
    image,
    imageAltText,
    sticker,
    productDetailUrl,
  } = props.product.product.value.data;

  const { t } = props;

  const onFocus = () => setIsActive(true);
  const onBlur = () => setIsActive(false);

  const shortDescriptionNoWidows = cleanOfWidowedWords(shortDescription);

  return (
    <li
      itemScope
      itemProp="itemListElement"
      itemType="https://schema.org/ListItem"
      /* @TODO: This is a fix for a builder preview issue.
      This should be fixed in the isAnimating useEffect */
      className={clsx(styles.listItem, Builder.isPreviewing && 'visible')}
      data-is-active={isActive}
      aria-hidden={!filters[props.selectedFilter](props.product)}
    >
      <Link href={`${productDetailUrl}`}>
        <a onFocus={onFocus} onBlur={onBlur}>{`${
          !shell && !almonds ? `${t('No Shells')} -` : ''
        } ${name}`}</a>
      </Link>
      <meta itemProp="url" content={`${productDetailUrl}`} />
      <meta itemProp="position" content={props.index.toString()} />
      <div className={styles.bagImageWrapper}>
        {image ? (
          <>
            <meta itemProp="image" content={image} />
            <BuilderImage
              imageSrc={image}
              mobileWidth={'300px'}
              alt={imageAltText || ''}
            />
          </>
        ) : null}
        {sticker && sticker !== 'none' && (
          <label className={styles.sticker}>{sticker}</label>
        )}
      </div>

      <span className={styles.productTitle} itemProp="name" aria-hidden={true}>
        {/* WPA-204 - Client Request to remove "No Shells" eyebrow title from PLP products */}
        {/* <span className={styles.noShellsText}>
          {!shell && !almonds && t('No Shells')}
          <br />
        </span> */}
        {name}
      </span>

      {shortDescriptionNoWidows && (
        <div
          itemProp="description"
          className={styles.productDescription}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(shortDescriptionNoWidows),
          }}
        />
      )}
    </li>
  );
}
