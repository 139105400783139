import { useEffect, MouseEvent, useState, KeyboardEvent } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Breakpoints } from './constants';
import { VimeoBuilderItem } from '@builder/VimeoGallery/VimeoGallery.types';
import { useRouter } from 'next/router';

export const formatPrice = (priceInCents: number, decimals = 2) => {
  const priceInDollars = priceInCents / 100;
  const priceString = priceInDollars.toFixed(decimals);
  const [integer, decimal] = priceString.split('.');
  return {
    price: priceString,
    integer,
    decimal,
    hasRemainder: parseInt(decimal) > 0,
  };
};

export function isObject(value: any) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

// For now UA Client Hints are available only on Chromium based browsers:
// https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1028
interface NavigatorUAData {
  readonly brands: ReadonlyArray<{
    brand?: string;
    version?: string;
  }>;
  readonly mobile: boolean;
  readonly platform: string;
}

interface NavWithClientHints extends Navigator {
  userAgentData: NavigatorUAData;
}

/**
 * USE ONLY ON THE CLIENT !!! Doesn't work on the Server.
 * @returns boolean - true, if script runs on mobile device. False on tablets and desktops.
 */
export function isMobile(): boolean {
  let nav = navigator as NavWithClientHints;
  if (nav?.userAgentData?.mobile === true) return true; // for now returns false for tablets, but we should validate it in future.

  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(nav?.userAgent || nav?.vendor || '');
  return check;
}

export function availabilityAsString(count: number) {
  switch (true) {
    case count < 1:
    default:
      return 'Out of stock';
    case count === 1:
      return 'The last one!';
    case count < 20:
      return 'Only few left!';
    case count >= 20:
      return 'In stock';
  }
}

export async function tryCatchRetry(
  callback: Function,
  errorCallback: Function,
  maxRetries: number = 3
) {
  let retry = true;
  let failCount = 0;

  while (retry) {
    try {
      await callback();
      retry = false;
    } catch (error) {
      console.error('error :>> ', error);
      await errorCallback();
      failCount++;
      if (failCount >= maxRetries) {
        retry = false;
      }
    }
  }
}

export function getEnumValues(enumType: any) {
  return Object.entries(enumType).map(([_, value]) => {
    return value as string;
  });
}

export function splitStringByCapitalLetters(word: string) {
  if (!word) return '';
  return word.split(/(?=[A-Z])/).join(' ');
}

export function useIsTablet() {
  const [isTabSize, setTabSize] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const onResize = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTabletDevice =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      setTabSize(
        window.innerWidth >= 768 && window.innerWidth <= 1024 && isTabletDevice
      );
    };
    onResize();
    window.addEventListener('resize', onResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isTabSize]);

  return isTabSize;
}

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const onResize = () => {
      setIsDesktop(window.innerWidth >= Breakpoints.lg);
    };
    onResize();
    window.addEventListener('resize', onResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isDesktop;
}

// check for URLs starting with http, https, mailto, tel
const urlRegexPattern = new RegExp(
  '^(http:|https:|mailto:|tel:)[\\w/?&=#-~.@]{2,}$'
);
export function isExternalLink(url: string) {
  let isExternal = false;
  if (urlRegexPattern.test(url)) {
    isExternal = true;
  }
  return isExternal;
}

const defaultFocusableElementsQuery =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

// respond to keyboard events by focusing different children
// of the event-ed element.
export function handleArrowKeyFocus(
  direction: 'horizontal' | 'vertical',
  focusableElementsQuery = defaultFocusableElementsQuery
) {
  const handleKeydown = (e: KeyboardEvent<HTMLElement>) => {
    // check which direction the focus should go (next/prev)
    let nextOrPrev: 'next' | 'prev' | undefined;
    if (direction === 'horizontal') {
      if (e.key === 'ArrowLeft') nextOrPrev = 'prev';
      else if (e.key === 'ArrowRight') nextOrPrev = 'next';
    } else if (direction === 'vertical') {
      if (e.key === 'ArrowUp') nextOrPrev = 'prev';
      else if (e.key === 'ArrowDown') nextOrPrev = 'next';
    }
    if (!nextOrPrev) return;

    // find the new element to focus among the children of the event-ed element
    const parent = e.currentTarget;
    if (parent.contains(document.activeElement)) {
      const links: NodeListOf<HTMLElement> = parent.querySelectorAll(
        focusableElementsQuery
      );
      let focusedIndex: number | null = null;
      for (let i = 0; i < links.length; i++) {
        if (
          links[i] === document.activeElement ||
          links[i].contains(document.activeElement)
        ) {
          focusedIndex = i;
          break;
        }
      }
      if (focusedIndex === null) return;
      let elementToFocus: HTMLElement | null | undefined;
      if (nextOrPrev === 'next') {
        elementToFocus = links[focusedIndex + 1];
      } else {
        elementToFocus = links[focusedIndex - 1];
      }
      if (
        elementToFocus &&
        !elementToFocus.matches(defaultFocusableElementsQuery)
      ) {
        elementToFocus = elementToFocus.querySelector<HTMLElement>(
          defaultFocusableElementsQuery
        );
      }
      if (elementToFocus) {
        e.preventDefault();
        elementToFocus.focus();
      }
    }
  };

  return handleKeydown;
}

export function stripHtml(htmlString: string) {
  return htmlString?.replace(/<[^>]*>/g, '');
}
export const cleanOfWidowedWords = (text: string | null | undefined) => {
  if (text) {
    const words = text.split(' ');
    const wLength = words.length;
    words[wLength - 2] += '&nbsp;' + words[wLength - 1];
    words.pop();
    return words.join(' ');
  }

  return text;
};

export const getVimeoCarouselData = async (videoCarouselObj: any) => {
  // build Array of Vimeo video IDs
  const vimeoVideoIds: number[] = [];

  // loop through videoCarouselObj passed from getStaticProps in [[...slug]].tsx
  videoCarouselObj?.forEach((videoItem: VimeoBuilderItem) => {
    const videoId = Number(videoItem.videoId);
    if (!isNaN(videoId)) {
      vimeoVideoIds.push(videoId);
    }
  });

  // hit vimeo oembed API to get vimeo title, image
  const response = Promise.all(
    vimeoVideoIds.map(async videoId => {
      const vimeoData = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}&width=1280&height=720`
      );
      return await vimeoData.json();
    })
  );
  const formattedVimeoData = (await response).map(data => ({
    video_id: data.video_id,
    title: data.title,
    thumbnail_url: data.thumbnail_url,
  }));
  return formattedVimeoData;
};

export const getSingleVimeoData = async (videoId: number) => {
  const response = await fetch(
    `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}&width=640`
  ).then(res => res.json());

  const formattedVimeoData = (({ video_id, title, thumbnail_url }) => ({
    video_id,
    title,
    thumbnail_url,
  }))(response);

  return formattedVimeoData;
};

export const addMultipleEventListener = (
  item: Element,
  type: string,
  handler: (e: Event) => void
) => {
  type.split(' ').forEach(e => {
    item.addEventListener(e, handler, false);
  });
};

export const removeMultipleEventListener = (
  item: Element,
  type: string,
  handler: (e: Event) => void
) => {
  type.split(' ').forEach(e => {
    item.removeEventListener(e, handler, false);
  });
};

export function useIsRtl() {
  const { locale } = useRouter();
  const rtlLocales = ['ar'];
  const isRtl = locale && rtlLocales?.includes(locale) ? true : false;

  return isRtl;
}

export function useIsAlmondPage() {
  const { asPath } = useRouter();
  const [isAlmondPage, setIsAlmondPage] = useState(false);

  useEffect(() => {
    if (asPath.indexOf('almonds') > -1) {
      setIsAlmondPage(true);
    } else {
      setIsAlmondPage(false);
    }
  }, [asPath]);

  return isAlmondPage;
}

export function handleRedirectClick(
  link: string,
  isPopupOpen: boolean | undefined,
  setIsPopupOpen?: Dispatch<SetStateAction<boolean>> | undefined,
  setRedirectLink?: Dispatch<SetStateAction<string>> | undefined,
  event?: MouseEvent<HTMLAnchorElement>
) {
  event?.preventDefault();
  setIsPopupOpen?.(!isPopupOpen);
  setRedirectLink?.(link);
}
