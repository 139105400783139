import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

import { CustomBuilderComponents } from '@store/enums';

const HeadingBlock = dynamic(async () => await import('./HeadingBlock'));

Builder.registerComponent(HeadingBlock, {
  name: CustomBuilderComponents.HEADING_BLOCK,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/toolbar-bottom.svg',
  inputs: [
    {
      name: 'header',
      type: 'string',
    },
    {
      name: 'subheader',
      type: 'customRichTextPistachios',
    },
    {
      name: 'image',
      type: 'object',
      subFields: [
        {
          name: 'src',
          friendlyName: 'Image source',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
        },
        {
          name: 'altText',
          friendlyName: 'Image description text',
          type: 'string',
        },
      ],
    },
  ],
});
